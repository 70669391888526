import { featureFlags } from './featureFlags';
import { version } from '../../package.json';

export const environment = {
  environment: 'DEV',
  production: false,
  version,
  lastUpdate: '2020-12-16 13:25',
  chatUrl: 'wss://chat.egzotech.dev',
  stellaAwareServerUrl: 'wss://direct-hub.egzotech.com',
  directHubFormat: 'msgpack',
  filtersConfigUrl: 'https://cdn.egzotech.com/config/filter-rms-3.json',
  authServerUrl: 'https://api.app.egzotech.dev/api/auth',
  restServerUrl: 'https://api.app.egzotech.dev/api',
  assetsUrl: 'https://storage.googleapis.com',
  gamesUrl: 'https://games-dev.stella.bio/',
  stimConfigurationUrl: 'https://git.egzotech.com/EGZOTech/stella-configuration-files/raw/master/stim-programs.json',
  stripePkey: 'pk_test_rLjQ6fkQqjOSP4kzVxjbXKXc00RdJOHFVf',
  expectStellaConnection: true,
  ...featureFlags,
  autoSelectClinic: true,
  autoSelectPatient: false,
  showRxDescriptions: true,
  maintenancePassword: '',
  telemedicine: true,
  allowDirectHubOverride: true,
  lightningChartLicense: null
};
